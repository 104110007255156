import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://7944495ba280454c80e73647c55b8cbe@o617827.ingest.sentry.io/5751258",
  release: "883aa7b6806518e07cb848cc28f2ebe26eeb48d2",
  environment: "production",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
});
